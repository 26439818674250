import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";

const Header = () => {

    return (
        <header>
            <div className="header-bar">
                <div className="container">
                    <span>Kundendienstzentrum +43 676 953 2222</span>
                    <div className="header-language">
                        <button className="btn-white-transparent">English</button>
                        <span className="separator"></span>
                        <button className="btn-white-transparent" >Deutsch</button>
                    </div>
                </div>
            </div>

            <div className="container header-nav">
                <div className="logo">
                    <NavLink to="/"><img src={logo} alt="HubTracTyres Logo" /></NavLink>
                </div>
                <nav>
                    <NavLink to="/">HOME</NavLink>
                    <NavLink to="/products">PRODUKTE</NavLink>
                    <NavLink to="/technology">TECHNOLOGIE</NavLink>
                    <NavLink to="/uber">ÜBER UNS</NavLink>
                    <NavLink to="/help">HELFEN</NavLink>
                    <NavLink to="/contact">KONTAKT</NavLink>
                    <NavLink to="/become-distributor">WERDE EIN VERTRIEBSPARTNER</NavLink>
                </nav>
            </div>
        </header>
    );
}
  
export default Header;
