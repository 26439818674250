import tireSliced from "../assets/images/tire-sliced.png";
import technologyBg from "../assets/images/technology-slider.jpg";

const TechnologyPage = () => {

    return (
        <section className="tehnologies-page">
            <div className="pages-bg">
                <img src={technologyBg} alt="Tires" />
                <div className="background-text">
                    <h1>Die Hubtrac-Technologien</h1>
                    <div className="underline"></div>
                </div>
            </div>
            <div className="tire-crooss">
                <div className="container">
                    <img src={tireSliced} alt="Tire cross-section" />
                </div>
            </div>
            <div className="container">
                <p
                    dangerouslySetInnerHTML={{
                        __html: `
                        Stressoptimierungskontrolltechnologie (SOCT) ist ein Konzept oder eine Technologie, die darauf abzielt, Stress in verschiedenen Systemen oder Anwendungen zu managen, wie z. B. in der Ingenieurwissenschaft, Materialwissenschaft, Softwareentwicklung oder im persönlichen Stressmanagement.<br /><br />
                        In der Ingenieur- oder Materialwissenschaft umfasst die Stressoptimierungskontrolltechnologie Techniken oder Methoden zur Gestaltung von Strukturen oder Materialien, die Stress besser standhalten oder die Spannungsverteilung optimieren, um Leistung und Haltbarkeit zu verbessern.<br /><br />
                        Im Zusammenhang mit Reifen bezieht sich SOCT auf eine Technologie oder Methode, die bei der Konstruktion und Herstellung von Reifen verwendet wird, um deren Leistung, Haltbarkeit und Sicherheit unter den verschiedenen Belastungen zu optimieren, denen sie im normalen Einsatz ausgesetzt sind.<br /><br />
                        <b>Die Hubtrac-Technologie implementiert SOCT in Reifen auf folgende Weise:</b><br />
                        <b>1. Laufflächendesign:</b> Optimierung des Profildesigns und der Profiltiefe, um eine bessere Traktion, Handhabung und Wasserableitung zu gewährleisten und gleichzeitig Spannungsbereiche zu minimieren, die zu ungleichmäßigem Verschleiß oder reduzierter Leistung führen können.<br />
                        <b>2. Materialauswahl:</b> Verwendung fortschrittlicher Materialien und Verbindungen, um die Fähigkeit des Reifens zu verbessern, Stress durch Hitze, Reibung, Straßenbedingungen und Belastung standzuhalten, während Flexibilität und Grip erhalten bleiben.<br />
                        <b>3. Karkassenkonstruktion:</b> Gestaltung der internen Struktur des Reifens, einschließlich der Anzahl und Ausrichtung der Lagen und Gürtel, um Belastungen gleichmäßig zu verteilen und Festigkeit sowie Stabilität zu gewährleisten.<br />
                        <b>4. Wärmeableitung:</b> Integration von Funktionen zur Steuerung der Wärmeentwicklung im Reifen, was dazu beitragen kann, die Belastung der Reifenkomponenten zu reduzieren und die Lebensdauer zu verlängern.<br />
                        <b>5. Reifendrucküberwachung:</b> Wo möglich, Implementierung von Reifendrucküberwachungssystemen (TPMS), um sicherzustellen, dass die Reifen mit dem richtigen Druck befüllt sind. Dadurch wird die Belastung des Reifens verringert und sowohl die Kraftstoffeffizienz als auch die Sicherheit verbessert.<br /><br />
                        Insgesamt zielt SOCT in Reifen darauf ab, Leistung, Sicherheit und Langlebigkeit zu verbessern, indem Belastungen, die Reifen während des normalen Gebrauchs erfahren, effektiv verwaltet und verteilt werden.
                        `,
                    }}
                />
            </div>
        </section>
    );
}
  
export default TechnologyPage;
