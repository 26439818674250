import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomaPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProductsPage from "./pages/ProductsPage";
import TechnologyPage from "./pages/TechnologyPage";
import DownloadPage from "./pages/DownloadPage";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/products' element={<ProductsPage />} />
        <Route path='/technology' element={<TechnologyPage />} />
        <Route path='/download' element={<DownloadPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
