import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";

const Footer = () => {

    return (
        <footer>
            <div className="footer-container container">
                <div className="footer-top">
                    <div className="footer-logo-nav">
                        <NavLink href="/" className="footer-logo">
                            <img src={logo} alt="Hubtrac Tyres logo" />
                        </NavLink>
                        <nav className="footer-nav">
                            <a href="/">Startseite</a>
                            <a href="/produkte">Produkte</a>
                            <a href="/ueber-uns">Über uns</a>
                            <a href="/hilfe">Hilfe</a>
                            <a href="/kontakt">Kontakt</a>
                            <a href="/haendler-werden">Händler werden</a>
                        </nav>
                    </div>

                    <div className="footer-newsletter">
                        <p>Für den Newsletter anmelden</p>
                        <form action="/newsletter" method="post" className="newsletter-form">
                            <input type="email" name="email" placeholder="Geben Sie Ihre E-Mail ein" required />
                            <button type="submit">Abonnieren</button>
                        </form>
                    </div>
                </div>

                <div className="footer-bottom">
                    <p>&copy; Copyright 2025, Alle Rechte vorbehalten</p>
                    <div className="footer-socials">
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
  
export default Footer;
