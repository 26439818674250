import downloadBg from "../assets/images/download-bg.jpg";

const DownloadPage = () => {

    return (
        <section className="download-page">
            <div className="pages-bg">
                <img src={downloadBg} alt="Download page background" />
                <div className="overlay"></div>
                <div className="background-text">
                    <h1>Die Hubtrac-Technologien</h1>
                    <div className="underline"></div>
                </div>
            </div>
        </section>
    );
}
  
export default DownloadPage;
